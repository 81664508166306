// import './App.css';
import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faBicycle, faPenNib, faArrowLeft, faArrowRight, faArrowDown, faArrowUp } from '@fortawesome/free-solid-svg-icons';

//
// Header
//
// Shows:
// - nb days to departure if not departed
// - nb days to destination if departed but not arrived yet
// - a nice short message when arrived
function Header(props) {
  let label;
  if( props.trip.daysToDeparture>0 && props.trip.daysToArrival>0 ) {
    label = <i><b>D-{props.trip.daysToDeparture}</b><br/>to departure!</i>;
  } else if( props.trip.daysToDeparture<=0 && props.trip.daysToArrival>0 ) {
    label = <i><b>D-{props.trip.daysToArrival}</b><br/>to<br/>Le Mont-Saint-Michel!</i>
  } else {
    label = <i><b>{props.trip.arrivalMessage}</b></i>
  }

  let velo = <img class="hidden sm:inline h-12 w-13" src="banner-velo.png" alt="Bicycle"/>;
  let path = <img class="hidden sm:inline h-12 w-20" src="banner-path.png" alt="Path"/>;
  let msml = <a href="banner-msm.png"><img class="h-24 w-44" src="banner-msm.png" alt="Le Mont-Saint-Michel"/></a>;

  return (
    <div class="h-24 flex flex-row bg-white items-center">
      <div class="w-32 p-2 text-xs text-center">{label}</div>

      <div class="flex-auto flex flex-row items-center">
        <div class="mx-auto flex flex-row items-end">
          { velo }
          { props.trip.daysToArrival>0 && path }
          { msml }
        </div>
      </div>
      <div class="w-32 p-2 text-xs text-center">
        <b>{props.trip.kmCompleted}</b> km completed<br/>
        <b>{props.trip.kmToGo}</b> km to go
      </div>
    </div>
  );
}


//
// Footer
//
// Shows:
// - the version number
function Footer(props) {
  return (
    <div class="mb-4 text-center text-xs text-slate-300">
      Made with ❤️ by <b>cnszym</b>
      <br/><span class="text-slate-100">- v{props.version} -</span>
    </div>
  );
}


//
// Coming soon
//
// Shows:
// - fixed text!
function ComingSoon(props) {
  return (
    // FIXME: duplicate code -> component text+image?
  <div class="sm:flex items-center justify-center m-4">
    <div class="sm:w-3/5 sm:maxi-w-md sm:pr-4 flex flex-col items-center justify-center text-center">
      <p class="mb-2 text-3xl text-slate-800"><b>Coming soon!</b></p>
      <p class="mx-4">
        The adventure has not yet started.<br/>
        We too are very excited to get going, but for now,
        let's be patient a bit longer while we get packed and prepared!<br/>
        <br/>
        <b>Please come back {props.trip.daysToDeparture>1?(<span>in {props.trip.daysToDeparture} days!</span>):'tomorrow! 😀'}</b><br/>
        Aneta &amp; Cyrille
      </p>
      <p class="mb-2 text-3xl">🚲 🚲</p>
    </div>
    <div class="sm:w-2/5 sm:maxi-w-md">
      <img class="mx-auto aspect-square rounded-xl shadow-lg" src="msm1.png" alt="Coming soon"/>
    </div>
  </div>);
}


//
// Arrived at destination
//
// Shows:
// - an arrival message
// - a fixed image! (TODO)
function ArrivedAtDestination(props) {
  return (
    // FIXME: duplicate code -> component text+image?
  <div class="sm:flex items-center justify-center m-4">
    <div class="sm:w-3/5 sm:maxi-w-md sm:pr-4 flex flex-col items-center justify-center text-center">
      <p class="mb-2 text-3xl text-slate-800"><b>{props.trip.arrivalMessage}</b></p>
      <p>
        {props.trip.arrivalText && props.trip.arrivalText.split('\n').map(t => <p>{t}</p>)}
      </p>
      <p class="mb-2 text-3xl">🏁</p>
    </div>
    <div class="sm:w-2/5 sm:maxi-w-md">
      <img class="mx-auto aspect-square rounded-xl shadow-lg" src="msm3.png" alt="Arrived at destination"/>
    </div>
  </div>);
}


//
// Picture Carousel
//
// props.pictureList = [
//   { src: 'image link', folder: 'optional folder added before image link' } 
// ]
function PictureCarousel(props) {  
  const [currentIndex, setCurrentIndex] = useState(0);

  function clickPrevious() {
    setCurrentIndex((currentIndex - 1 + props.pictureList.length) % props.pictureList.length);
  }
  function clickNext() {
    setCurrentIndex((currentIndex + 1) % props.pictureList.length);
  }

  // only show carousel if there is at least one image
  if( !props.pictureList || props.pictureList.length===0 )
  return <div></div>;

  return (
    <div>
      {/* Keep aspect ratio and display image inside */}
      <div class="relative flex aspect-[3/2]" onClick={clickNext}>
        <img
          class="absolute inset-0 m-auto max-h-full max-w-full object-contain border border-solid border-gray-300 rounded-lg shadow-md"
          src={(props.pictureList[currentIndex].folder?props.pictureList[currentIndex].folder+'/':'')+props.pictureList[currentIndex].src}
          alt="Carousel"
        />
      </div>
      {/* Only show carousel navigation buttons if more than 1 image */}
      { props.pictureList.length>1 && <p class="mt-1">
        <span class="hover:text-sky-500 cursor-pointer" onClick={clickPrevious}><FontAwesomeIcon icon={faArrowLeft}/></span>
        &nbsp;&nbsp;&nbsp;&nbsp;{currentIndex+1}/{props.pictureList.length}&nbsp;&nbsp;&nbsp;&nbsp;
        <span class="hover:text-sky-500 cursor-pointer" onClick={clickNext}><FontAwesomeIcon icon={faArrowRight}/></span>
      </p> }
    </div>
  );
}


//
// Trip Comments
//
// Params:
// - props.trip
// - props.postId
function TripComments(props) {

  const [commentsVisible, setCommentsVisible] = useState(false);
  const [postCommentVisible, setPostCommentVisible] = useState(false);

  const [postComments, setPostComments] = useState([]);
  function fetchComments(postId) {
    fetch(`trip-post-comments/${props.postId}`).then(res => res.json()).then(data => { setPostComments(data); });
  }

  function sendForm() {
    alert('Your message has been submitted 🙏\n\nWe will validate it as soon as we are done biking for the day.\n\nThanks for cheering us today! 👏👏👏');
  }

  return (
  <div>
    <p class="mb-2 text-xs text-slate-300">
      <span class="hover:text-sky-500 cursor-pointer" onClick={() => {setCommentsVisible(!commentsVisible); if(!commentsVisible) fetchComments(props.postId);}}>{ commentsVisible ? 'HIDE' : 'SHOW'} COMMENTS <FontAwesomeIcon icon={commentsVisible ? faArrowUp : faArrowDown}/></span>
      &nbsp;&nbsp;&nbsp;
      <span class="hover:text-sky-500 cursor-pointer" onClick={() => {setPostCommentVisible(!postCommentVisible)}}>POST A COMMENT <FontAwesomeIcon icon={faPenNib}/></span>
    </p>
    { postCommentVisible && <p>
      <form action={`trip-post-comment/${props.postId}`} method="POST">
        Name:<br/><input class="border border-sky-300 rounded-md" type="text" name="author"/><br/>
        Message:<br/><textarea class="border border-sky-300 rounded-md w-full" name="message"></textarea><br/>
        <button class="border border-gray-300 bg-gray-100 rounded-md p-2" onClick={() =>{sendForm()}}>Submit</button>
      </form>
    </p>}
    { commentsVisible && <ul class="ml-4 mb-2 list-disc text-xs text-slate-400">
      { postComments.length===0 && <p>No comments yet, please add one above! We can't wait to hear from you! ❤️</p>}
      { postComments.map((comment) => {
        return (<li>
        <span class="text-slate-400">{comment.message}</span>
        <span class="text-slate-300">&nbsp;by {comment.author}
        { !comment.isVisible && <span class="text-red-400">&nbsp;- NOT PUBLISHED</span>}</span>
      </li>);
      }) }
    </ul> }
  </div>);
}


//
// Trip of the day
//
// Shows:
// - day's label, day's route or location
// - day's distance
// - if planned or realized
// - post date
// - post title
// - post message
// - link to the planned or actual route
// - pictures (hidden: from the designated folder)
// - hidden: if it is published or not
//
// Params:
// - props.trip
// - props.postId
function TripPost(props) {

  // fetch trip post
  const [tp, setTP] = useState();
  useEffect(() => {
    fetch(`trip-post/${props.postId}`).then(res => res.json()).then(data => setTP(data));
  }, [props.postId]); // call only once

  // only display the post if there is one to show
  if( !tp )
    return <div></div>;

  let hasPictureCarousel = tp.pictureList && tp.pictureList.length>0;

  return (
    // FIXME: duplicate code -> component text+2*image?
  <div class="sm:flex items-center justify-center m-4">
    <div class={"sm:pr-4 flex flex-col justify-center"+(hasPictureCarousel?" sm:w-3/5":" sm:w-full")}>
      {/* Displayed on the debug version of the site */}
      { !tp.postIsPublished && <p class="text-sm text-red-400">NOT PUBLISHED</p> }
      {/* Title */}
      <p class="mb-2 text-xl text-slate-800">
        <b>{tp.postTitle && tp.postTitle}</b>
      </p>
      {/* Information */}
      <p class="mb-2 text-xs">
        { (tp.dayLabel || tp.dayLocation) && <FontAwesomeIcon icon={faCalendar}/> } {tp.dayLabel && tp.dayLabel} 👉 {tp.dayLocation && tp.dayLocation}{ (tp.dayLabel || tp.dayLocation) && <br/>}
        { tp.dayRouteKm>0 && <div><FontAwesomeIcon icon={faBicycle}/> Distance {tp.dayRouteKm} km ({tp.dayRouteIsReal?'realized':'planned'})<br/></div> }
      </p>
      <p class="mb-2 text-xs text-slate-300">
        <span class="text-slate-300"><FontAwesomeIcon icon={faPenNib}/> <i>Posted on {tp.postDateString}</i></span>
      </p>
      {/* Main message */}
      <p class="mb-2 text-slate-800">
        {tp.postMessage && tp.postMessage.split('\n').map(t => <p>{t}</p>)}
      </p>
      {/* Route link (only shown when there is a link available) */}
      { tp.dayRouteLink &&
      <div class="mb-2 flex flex-row items-center justify-center text-center">
        <div class="w-40 text-xs"><i>Click to view the route we have {tp.dayRouteIsReal?'realized':'planned'}</i></div>
        <div class="mx-5">
          <a href={tp.dayRouteLink} target="_blank" rel="noreferrer">
            <img class="max-h-40 sm:max-h-48 object-contain border-solid border border-slate-100 hover:border-sky-200 rounded-lg shadow-sm" src={tp.dayRouteImage?tp.dayRouteImage:"itineraire-veloscenie.png"} alt="Map of the day"/>
          </a>
        </div>
      </div> }
      <TripComments trip={props.trip} postId={props.postId} />
    </div>
    { hasPictureCarousel && <div class="sm:w-2/5 text-xl text-center">
      <PictureCarousel trip={props.trip} pictureList={tp.pictureList} />
    </div> }
  </div>);
}

//
// Params:
// - props.trip
// - props.tripPosts
function Replay(props) {
  const [currentIndex, setCurrentIndex] = useState(1);

  function clickPrevious() {
    if( currentIndex <= 1 )
      setCurrentIndex(props.tripPosts.length - 1);
    else
      setCurrentIndex(currentIndex - 1);
  }
  function clickNext() {
    if( currentIndex >= props.tripPosts.length - 1)
      setCurrentIndex(1);
    else
      setCurrentIndex(currentIndex + 1);
  }

  // only show previous posts if there is at least one to show
  if( !props.tripPosts || props.tripPosts.length<2 )
    return <div></div>;

  return (
    <div>
      <hr/>
      <p class="m-4 mt-6">
        PREVIOUSLY POSTED
        { props.tripPosts.length>2 && <span>
          <span class="px-3 py-2 m-5 hover:text-sky-500 cursor-pointer border-slate-100 border-2 bg-slate-100 hover:border-slate-200 rounded-md" onClick={clickPrevious}><FontAwesomeIcon icon={faArrowLeft}/></span>
          <span class="px-3 py-2 hover:text-sky-500 cursor-pointer border-slate-100 border-2 bg-slate-100 hover:border-slate-200 rounded-md" onClick={clickNext}><FontAwesomeIcon icon={faArrowRight}/></span>
        </span> }
      </p>
      <TripPost trip={props.trip} postId={props.tripPosts[currentIndex]} />
    </div>);
}


//
// Overall route
//
// Shows:
// - the overall route image
function OverallRoute(props) {
  return (
    <div>
      <hr/><hr/>
      <img class="mx-auto mt-4 mb-2 object-cover" src={props.trip.tripOverallRoutePicture} alt="Planned overview"/>
      <div class="mb-4 text-center">We will be following the route <a class="text-blue-800 underline hover:text-blue-600" target="_blank" rel="noreferrer" href="https://www.veloscenie.com/">la Véloscénie</a>
      <br/>from Antony to Le Mont-Saint-Michel!</div>
    </div>);
}

//
// Main App
//
function App() {
  const [version, setVersion] = useState('*');
  useEffect( () => {
    // get the site version
    fetch('version').then(res => res.json()).then(data => { setVersion(data.version); });
  }, []); // called only once

  const [trip, setTrip] = useState();
  const [tripPosts, setTripPosts] = useState();
  useEffect( () => {
    // get the trip information
    fetch('trip').then(res => res.json()).then(data => {
      // decode the dates
      data.departureDate = new Date(data.departureDate);
      data.arrivalDate = new Date(data.arrivalDate);
      // the rest is OK
      setTrip(data);

      // get the list of posts
      fetch('trip-posts').then(res => res.json()).then(data => {
        setTripPosts(data);
      });
    });
  }, []); // called only once

  if( !trip ) {
    return <div></div>;
  } else {
    return (
      <div class="sm:max-w-4xl mx-auto text-slate-500 text-sm">
        {/* Always show the header */}
        <Header trip={trip} />

        {/* Show the coming soon section when not departed yet */}
        { trip.daysToDeparture>0 && <ComingSoon trip={trip} /> }
        {/* ...or show the arrived at destination section when arrived */}
        { trip.daysToArrival<=0 && <ArrivedAtDestination trip={trip} /> }

        {/* Show the latest published post, if any */}
        { tripPosts && tripPosts.length>0 &&  <TripPost trip={trip} postId={tripPosts[0]} /> }

        {/* FIXME Show earlier posts, if any */}
        { tripPosts && tripPosts.length>1 && <Replay trip={trip} tripPosts={tripPosts} /> }

        {/* Always show the overall route and the footer */}
        <OverallRoute trip={trip} />
        <Footer trip={trip} version={version}/>
      </div>
    );
  }
}

export default App;
